export const traitDescriptions = {
    "Aberration Type": "This has no innate effect, but is considered a Benefit because it qualifies for so many special abilities.",
    "Arcane Aversion": "Incur a penalty to your Spellcraft Check and the Accuracy of your spell attacks. This penalty is equal to any encumbrance penalties you suffer to your Athletics, Dexterity, and Stealth skills (due to armor, shield, or a heavy load). The penalty additionally worsens by 1 if you wear a shield and again by 1 if you wear armor.",
    "Beast Type": "Cannot speak normal languages. Illiterate. Limited in the Charisma, Dexterity, Gadgetry, Glibness, and Knowledge skills.",
    "Blight-Defiant": "If you are Blight-Snared, ignore the worst two stages of its effects. Your saving throws against new stages of Blight are never capped due to the Blight's other effects.",
    "Blight-Embraced": "You are immune to the Blight Affliciton. However, you can be detected and targeted as a Blighted creature. Also, you are pretty close to irredeemably evil.",
    "Blight-Snared": "You may choose to become Blight-Embraced when you take an extended rest. If you are not Blight-Embraced, you are subject to an exposure of the Blight Affliction each day. Even if you manage to stave off all stages of the Blight Affliction, you can be detected and targeted as a Blighted creature.",
    "Bully": "Your skill checks to intimidate are boosted, as long as the target of your intimidation is not a larger size category than you.",
    "Climber": "You can coast Athletics checks to climb, even under pressure.",
    "Colossal Size": "Your Perception checks to spot Gargantuan or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Gargantuan or smaller creatures are boosted. Your Stealth checks to hide from Gargantuan or smaller creatures are dragged.",
    "Darkvision": "Treat nonmagical darkness as if it were shadowy illumination.",
    "Diminutive Size": "Your Stealth checks to hide from Tiny or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Tiny or larger creatures are dragged. Your Perception checks to spot Fine creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Fine creatures are boosted. Your Stealth checks to hide from Fine creatures are dragged.",
    "Dragon Type": "Limited in the Dexterity and Gadgetry skills.",
    "Epic Continuation: VP": "Gain 2 VP for each level above 8 that you have attained.",
    "Expert Parry": "While you wield a Heavy Blade weapon, gain a +1 Feat bonus to your Defense save.",
    "Fine Size": "Your Stealth checks to hide from Diminutive or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Diminutive or larger creatures are dragged.",
    "Gargantuan Size": "Your Stealth checks to hide from Colossal creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Colossal creatures are dragged. Your Perception checks to spot Huge or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Huge or smaller creatures are boosted. Your Stealth checks to hide from Huge or smaller creatures are dragged.",
    "Giant Type": "This has no innate effect.",
    "Haggler": "When you roll Wealth to buy something, change one d6 to a success. When you roll Wealth to sell something, change one d6 to a failure.",
    "Hit Points": "You are immune to Attrition Damage.",
    "Huge Size": "Your Stealth checks to hide from Gargantuan or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Gargantuan or larger creatures are dragged. Your Perception checks to spot Large or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Large or smaller creatures are boosted. Your Stealth checks to hide from Large or smaller creatures are dragged.",
    "Ignore Armor Speed Penalty": "Ignore Speed penalty for wearing heavy armor (or chainmail), as long as you're proficient.",
    "Instinctual": "Cannot speak normal languages. Illiterate. Limited in the Gadgetry, Glibness, and Knowledge skills.",
    "Instinctual Immunities": "You are immune to [Fear] and [Charm] effects.",
    "Large Size": "Your Stealth checks to hide from Huge or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Huge or larger creatures are dragged. Your Perception checks to spot Medium or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Medium or smaller creatures are boosted. Your Stealth checks to hide from Medium or smaller creatures are dragged.",
    "Low-Light Vision": "Treat nonmagical shadowy illumination as well-lit.",
    "Martial Weaponry": "You are proficient with all martial weapons and with light armor. (You may immediately retrain Light Armor Proficiency.)",
    "Medium Size": "Your Stealth checks to hide from Large or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Large or larger creatures are dragged. Your Perception checks to spot Small or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Small or smaller creatures are boosted. Your Stealth checks to hide from Small or smaller creatures are dragged.",
    "Outsider Type": "You are not native to the Material Plane. While not on your native plane, you are considered [Extraplanar] and therefore subject to certain spells or rituals that banish.",
    "Skill Ranks for Parcels": "Gain a bonus skill rank for each XP Parcel.",
    "Small Size": "Your Stealth checks to hide from Medium or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Medium or larger creatures are dragged. Your Perception checks to spot Tiny or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Tiny or smaller creatures are boosted. Your Stealth checks to hide from Tiny or smaller creatures are dragged.",
    "Stability": "Your Athletics or Brawn checks to maintain your footing are boosted.",
    "Stack 1st-Level Kits": "If you took two Kits at Level 1 that give you non-stacking bonuses to your stats (such as Fighting Level, Caster Level, Coast Number, or VP), those bonuses now stack.",
    "Swarm Resilience": "You resist attacks that would normally have a single target, including most weapon attacks. You are immune to conditions that originate from effects that normally have a single target. Foes do not gain Combat Advantage for flanking you.",
    "Swarm Vulnerabilities": "An attack that affects an area or multiple targets may affect you more than once by targeting your Spawns as if they were separate creatures. While you have no active Spawns, foes may inflict Hazards on you as if you were Wounded. If any effect would cause you to be Dropped, you instead dissipate.",
    "Tall Weapon Reach": "Gain a +1 Size bonus to your melee weapons' reach for each size category bigger than Medium that you are.",
    "Tiny Size": "Your Stealth checks to hide from Small or larger creatures are boosted. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Small or larger creatures are dragged. Your Perception checks to spot Diminutive or smaller creatures are dragged. Your Athletics or Brawn checks to keep your footing or resist Grabbing against Diminutive or smaller creatures are boosted. Your Stealth checks to hide from Diminutive or smaller creatures are dragged.",
    "Unarmed Focus": "You are proficient with your Unarmed Strike. You may freely choose whether your Unarmed Strike attacks deal subdual damage or not.",
    "Undead Type": "This overrides other Type traits. You are immune to diseases and poison (unless the specific disease or poison states otherwise).",
    "Whiff of Blight": "You can be detected and targeted as a Blighted creature.",
    "Wilds' Aversion": "If you wear metal armor or wield a metal shield, incur a -2 penalty to your Spellcraft and your spell attack accuracy.",
    "Wing-Aided Movement": "Your Athletics checks to balance on a narrow surface or jump are boosted. You can control the speed at which you fall somewhat, enough to avoid taking falling damage if you desire. When you fall, you may glide 4 ft horizontally for each 1 ft you fall vertically.",
    "Wound-Tolerant": "You do not suffer the normal -2 penalty to saving throws for being Wounded."
}