const gc = {
    base_armor_value: 2,
    base_awesome_bonus: 4,
    base_coast_number: 6,
    base_initial_wealth: 20,
    base_reserve_points: 4,
    base_speed: 15,
    base_trained_skills: 2,
    base_vitality_points: 5,
    basic_conditions: [
        "Momentum",
        "Exerted",
        "Wounded",
        "Dropped"
    ],
    benefit_traits: [
        "Aberration Type",
        "Blight-Defiant",
        "Bully",
        "Climber",
        "Darkvision",
        "Epic Continuation: VP",
        "Expert Parry",
        "Haggler",
        "Hit Points",
        "Ignore Armor Speed Penalty",
        "Instinctual Immunities",
        "Low-Light Vision",
        "Martial Weaponry",
        "Skill Ranks for Parcels",
        "Stability",
        "Stack 1st-Level Kits",
        "Swarm Resilience",
        "Tall Weapon Reach",
        "Unarmed Focus",
        "Undead Type",
        "Wing-Aided Movement",
        "Wound-Tolerant"
    ],
    bonus_targets: [
        { name: "Armor Value", code: "av_mods" },
        { name: "Athletics", code: "Athletics" },
        { name: "Awesome", code: "awesome_mods" },
        { name: "Brawn", code: "Brawn" },
        { name: "Charisma", code: "Charisma" },
        { name: "Defense", code: "defense_mods" },
        { name: "Dexterity", code: "Dexterity" },
        { name: "Fortitude", code: "fortitude_mods" },
        { name: "Gadgetry", code: "Gadgetry" },
        { name: "Glibness", code: "Glibness" },
        { name: "Knowledge", code: "Knowledge" },
        { name: "Magic Points Pool", code: "mp_mods" },
        { name: "Nature", code: "Nature" },
        { name: "Perception", code: "Perception" },
        { name: "Reflex", code: "reflex_mods" },
        { name: "Reserve Points Pool", code: "rp_mods" },
        { name: "Size", code: "size_mods" },
        { name: "Speed", code: "speed_mods" },
        { name: "Spell Accuracy", code: "spell_accuracy_mods" },
        { name: "Spellcraft", code: "spellcraft_mods" },
        { name: "Spell Impact", code: "spell_impact_mods" },
        { name: "Stealth", code: "Stealth" },
        { name: "Vim Accuracy", code: "vim_accuracy_mods" },
        { name: "Vim Impact", code: "vim_impact_mods" },
        { name: "Vim Range", code: "vim_range_mods" },
        { name: "Wealth (one-time)", code: "wealth_mods" },
        { name: "Weapon Accuracy", code: "weapon_accuracy_mods" },
        { name: "Weapon Impact", code: "weapon_impact_mods" },
        { name: "Weapon Range", code: "weapon_range_mods" },
        { name: "Willpower", code: "willpower_mods" },
    ],
    bonus_types: [
        "base",
        "Circumstance",
        "Deflection",
        "Encumbrance",
        "Feat",
        "Item",
        "Racial",
        "Size",
        "Synergy",
        "TwoHanded",
        "Untyped"
    ],
    damage_types: [
        "acid",
        "bludgeoning",
        "cold",
        "fire",
        "lightning",
        "necrotic",
        "piercing",
        "poison",
        "psychic",
        "radiant",
        "slashing",
        "thunder",
        "typeless"
    ],
    drawback_traits: [
        "Arcane Aversion",
        "Beast Type",
        "Blight-Embraced",
        "Blight-Snared",
        "Dragon Type",
        "Giant Type",
        "Instinctual",
        "Outsider Type",
        "Swarm Vulnerabilities",
        "Whiff of Blight",
        "Wilds' Aversion"
    ],
    epic_level_xp_increment: 500,
    feat_tags: [
        "Boost",
        "Buff",
        "Core",
        "Counter",
        "Grace",
        "Ki",
        "Luck",
        "Monster",
        "Spell",
        "Stance",
        "Strike"
    ],
    good_save_boost: 2,
    item_tags: [
        "Armor",
        "Consumable",
        "Container",
        "Gear",
        "Magical",
        "Weapon"
    ],
    kit_tags: [
        "Background",
        "Core",
        "Epic",
        "Heritage",
        "Monster",
        "Racial"
    ],
    max_level_pre_epic: 8,
    other_conditions: [
        "Unconscious",
        "Dying",
        "Shaken",
        "Prone",
        "Dazed",
        "Stunned"
    ],
    resistance_boost: 4,
    ritual_tags: [
        "Arcane",
        "Divination",
        "Divine",
        "Healing",
        "Item Creation",
        "Primal",
        "Vile"
    ],
    size_categories: {
        "-5": "Fine Size",
        "-4": "Fine Size",
        "-3": "Diminutive Size",
        "-2": "Tiny Size",
        "-1": "Small Size",
        "0": "Medium Size",
        "1": "Large Size",
        "2": "Huge Size",
        "3": "Gargantuan Size",
        "4": "Colossal Size",
        "5": "Colossal Size",
    },
    skills_list: [
        "Athletics",
        "Brawn",
        "Charisma",
        "Dexterity",
        "Gadgetry",
        "Glibness",
        "Knowledge",
        "Nature",
        "Perception",
        "Stealth"
    ],
    skills_list_num: 10,
    skill_ranks_per_level: 5,
    talent_tags: [
        "Aura",
        "Background",
        "Buff",
        "Core",
        "Epic",
        "Flaw",
        "Form",
        "Grace",
        "IllusorySeed",
        "Ki",
        "Luck",
        "Metamagic",
        "Monster",
        "Proficiency",
        "Seed",
        "Skill Trick",
        "Style"
    ],
    trained_skill_extra_ranks: 2,
    weapon_categories: [
        "Axe",
        "Bow",
        "Club",
        "Crossbow",
        "Flail",
        "Heavy Blade",
        "Light Blade",
        "Polearm",
        "Spear"
    ],
    weapon_hefts: [
        "Light",
        "One-Handed",
        "Versatile",
        "Two-Handed"
    ],
    wounded_save_penalty: -2,
    xp_award_per_level_of_monster: 40,
    xp_buffer_by_level: {
        1: 5,
        2: 10,
        3: 15,
        4: 20,
        5: 25,
        6: 30,
        7: 35,
        8: 40
    },
    xp_increment_per_level: 100,
    xp_per_parcel: 30
};

export default gc;